export const INDIVIDUAL_IMMIGRATION_TESTIMONIALS = [
	{
		text: 'Pandev Law has the best team of professionals I have ever seen. I came across this firm while looking for immigration lawyers for my green card process. From the initial 30 minutes consultation with Adrian I realized I can trust them with my case. Michael and the rest of the team worked with me during my whole EB1A process. They were friendly, professional, hard working, responsive and delivered everything on time. ',
		name: 'Varshini V',
		stars: 5,
	},
	{
		text: "I would give him ten stars out of five if I could. The best decision I have ever made for my citizenship application was to go with Pandev Law.  Attorney Pandev is an excellent legal counsel, and his expertise has never failed to impress me at every single step of my application process.  I am definitely asking him to help out with my family members' application(s) in the future.",
		name: 'Joyce Lee',
		stars: 5,
	},
	{
		text: 'This is a great law firm. Adrian help me throughout the naturalization process, guiding me, and making sure that everything in order. Highly recommend him.\r',
		name: 'Lamiaa Rd',
		stars: 5,
	},
	{
		text: 'I am a Canadian artist who needed help with US Immigration. Adrian advised me on the right visa and guided me through the entire process. Highly recommended to any foreign artist looking to work in the US',
		name: 'Pawel O',
		stars: 5,
	},
	{
		text: "This is the best experience I have had in my life and I  HIGHLY recommend Pandev Law.  I am delighted to have an opportunity to express my gratitude and appreciation to Adrian Pandev and his friendly staff for diligently caring for our immigration needs.  Adrian and his staff are very knowledgeable, super friendly, capable staff with quick service! They are not only most professional but attentive and caring.\n\nMy daughter and I were on a J visa. I got married and found out I was unable to change my daughter's status because she was over the age of 18 when we got married.\n\nI contacted several Attorneys who told me that it was impossible to file for my daughter as she was over the age and her J2 visa had expired.\n\nSearching for an Attorney I found Adrian right here on google. I scheduled a consultation and Adrian jumped on the case immediately and told me that it was possible. Even though Adrian told me that it was possible he was very honest about what could go wrong. It wasn't all about the money that impressed me the most like the other attorneys I spoke to.\n\nAdrian advised us that my daughter had to return to our home country and switch visas. We had followed all the instructions he gave us. My daughter received her visa and returned to the United States.\n\nAdrian filed my daughter's paperwork and she received her Green card in less than a year. Matt accompany us to the interview he is an amazing person with the best personality, knowledgeable, and well organized.\n\nI want to publicly thank the following persons' names listed below for the awesome representation and professionalism: Adrian Pandev, Maria Yachkova, Ivanela Arabadzhieva,  Dary Markova, and Matt Bush. We are forever grateful for everything you did for my family!\n\nLook no further make it PANDEV LAW for your Immigration Needs!!",
		name: 'Nicola Smiling',
		stars: 5,
	},
	{
		text: 'I strongly recommend Pandev Law to anyone applying for their green card or naturalization. Adrian and his team move fast, are persistent, work very closely to prepare your application, and are comprehensive. I would definitely welcome the opportunity work with his team again!',
		name: 'Shameek Ray',
		stars: 5,
	},
	{
		text: 'Adrian offered great assistant to navigate me through issue I have with the F-1 student Visa and the OPT program, and I highly recommend Pandev Law if you have any similar issues!',
		name: 'Tian Qiu',
		stars: 5,
	},
	{
		text: 'Adrian and his team were so helpful throughout the whole process. They helped me change my immigrations status from J1 to F1 and I would highly recommend Pandev Law to anyone who’s looking for an immigration attorney. Adrian is very professional and I was 100% satisfied with his service.',
		name: 'Barbora Ocenasova',
		stars: 5,
	},
	{
		text: 'Adrian has all the great qualities that an immigration lawyer should have. He’s extremely qualified and updated on immigration laws, he is a great fount of advises, very sharp, always cordial, he’s on top of his game! I used his service for my naturalization process: he analyzed my case, suggested the best option for me, guided me through the whole process, prepared me the test and even joined me during the interview.',
		name: 'Francesco Superchi',
		stars: 5,
	},
];
