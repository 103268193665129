import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import get from 'lodash.get';
import Layout from '../components/layout';
import Hero from '../components/common/hero';
import heroImg from '../assets/img/individual-immigration/individual-immigration.jpg';
import CtaDeck from '../components/common/cta-deck';
import SuccessStoriesDeck from '../components/common/Success-stories-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';
import { INDIVIDUAL_IMMIGRATION_TESTIMONIALS } from '../data/individual-immigration-services/testimonials';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import CategorySlider from '../components/blog/category-slider';
import Reviewer from '../components/common/reviewer';
import Signature from '../components/common/signature';
import { prependStaticURL } from '../utils';

const headMeta = {
  title: 'Individual Immigration Services | Pandev Law',
  description:
    'Pandev Law provides strategic solutions to the numerous immigration issues faced by foreign nationals residing in, traveling to, or relocating to the US.',
  keywords:
    'investor immigration,investor visa USA,entrepreneur visa USA,US green card by investment',
};

const schema = `[
  {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "Individual Immigration Services | Pandev Law",
    "description": "Pandev Law provides strategic solutions to the numerous immigration issues faced by foreign nationals residing in, traveling to, or relocating to the US.",
    "url": "https://www.pandevlaw.com/individual-immigration-services",
    "image": "https://pandevlaw.com/img/individual-immigration.jpg",
    "brand": "Pandev Law",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "0",
      "ratingCount": "246"
    },
    "sameAs": [
      "https://en-gb.facebook.com/pandevlaw/"
    ]
  },
  {
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.pandevlaw.com/individual-immigration-services/"
  },
  "headline": "Individual Immigration",
  "description": ${JSON.stringify(headMeta.description)},
  "image": ${prependStaticURL(heroImg)},  
  "author": {
    "@type": "Person",
    "name": "Adrian Pandev",
    "url": "https://www.pandevlaw.com/about/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Pandev Law",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.pandevlaw.com/static/13437dd4aac9a31bf6176b69797f47be/61fd1/logo.webp"
    }
  },
  "datePublished": ""
}]`;

function IndividualImmigration({ location, data }) {
  const posts = get(data, 'posts', null);
  const category = get(data, 'category', null);

  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero title="Individual Immigration" img={heroImg} imgAlt="Individual Immigration">
        <p className="text-lead mb-5 mb-md-6">
          Pandev Law’s individual immigration practice provides strategic solutions to the
          numerous immigration issues faced by foreign nationals residing in, traveling
          to, or relocating to the United States. As a full service immigration law firm,
          our attorneys have the necessary depth and breadth of experience to develop
          effective immigration strategies in even the most complex cases. Our individual
          immigration practice has counseled clients on various immigration matters,
          including applications for naturalization, student visas, waivers, and
          expatriation, among many others. To meet the multi-front challenges of
          immigration cases, our attorneys use a multidisciplinary approach, combining our
          immigration, business, and wealth protection practices to deliver effective
          immigration strategies to clients nationwide and around the world:
        </p>
      </Hero>

      <AbTestingDeck
        position="top"
        title="Individual Immigration Client Reviews"
        testimonials={INDIVIDUAL_IMMIGRATION_TESTIMONIALS}
      />

      <div className="section pt-5 pb-5 pt-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/individual-immigration/us-citizenship.jpg"
                alt="US Citizenship"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <Reviewer />
              <h2 className="text-headline mb-5 mb-md-6">US Citizenship</h2>
              <p className="text-body mb-6">
                Our immigration attorneys have extensive experience helping individuals
                obtain US citizenship. Becoming a US citizen grants many rights and
                privileges, including the ability to vote in US elections and the ability
                to work for the federal government. To become a US citizen you must either
                acquire or derive citizenship through a US citizen parent or apply for
                naturalization. Our talented team of immigration lawyers provides
                coordinated, effective counsel on how to become a US citizen:
              </p>
              <div className="accordion" id="corporateLaw">
                <div className="mb-5">
                  <div id="corporateLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseOne"
                      aria-expanded="true"
                      aria-controls="corporateLawCollapseOne">
                      <strong>Naturalization</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseOne"
                    className="collapse show"
                    aria-labelledby="corporateLawHeadingOne"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      If you have lived in the United States as a permanent resident for
                      five years, you may be eligible to become a naturalized US citizen.
                      For permanent residents who have been living in marital union with a
                      US citizen for three years, the five-year requirement is reduced to
                      three years. In addition to the mandatory years of permanent
                      residence, to be eligible for naturalization, you must demonstrate
                      that you meet certain physical presence and continuous residence
                      requirements. Prior criminal convictions or immigration violations
                      can pose an obstacle to naturalization. Our attorneys have an
                      in-depth and personal understanding of the naturalization process.
                      In addition to having filed numerous naturalization applications for
                      clients over the years, our principal attorney is a naturalized US
                      citizen. This gives us personal experience in addition to our
                      professional experience with the naturalization process. Pandev Law
                      is known for devising creative solutions even in the most complex
                      cases, representing applicants who spend a significant portion of
                      the year overseas, who owe back taxes, or who have serious criminal
                      convictions.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseTwo"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseTwo">
                      <strong>Certificate of Citizenship</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseTwo"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingTwo"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      <p className="mt-4">
                        If you were born outside the United States to a US citizen parent,
                        you are under eighteen, and currently in the United States, you
                        may be eligible to apply for a Certificate of Citizenship. The
                        Certificate of Citizenship process is different from
                        naturalization because it is reserved for applicants who have
                        acquired or derived citizenship through a US citizen parent. You
                        are eligible for US citizenship through acquisition if you were
                        born to a US citizen parent, whether biological or adoptive,
                        outside the United States. You derive US citizenship if your
                        foreign national parent became a US citizen through naturalization
                        before your eighteenth birthday. The Certificate of Citizenship is
                        a critical document which serves as unquestionable proof of your
                        US citizenship. Our immigration lawyers understand the Certificate
                        of Citizenship application process and can represent you before US
                        Citizenship and Immigration Services (USCIS) in obtaining this
                        critical document.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingThree">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseThree"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseThree">
                      <strong>Consular Report of Birth Abroad</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseThree"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingThree"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      A Consular Report of Birth Abroad is issued to children born to US
                      citizen parents outside the United States. It is a critical document
                      that serves as proof of US citizenship. To obtain a Consular Report
                      of Birth Abroad for your child, you must file an application
                      including all relevant evidence with the US Department of State,
                      specifically the embassy or consulate in your child’s country of
                      birth. Our immigration attorneys represent clients before US
                      consulates worldwide. We have extensive experience working with
                      consular officers and guiding clients through the Consular Report of
                      Birth Abroad application process.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/individual-immigration/student-visa.jpg"
                alt="Student Visa"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Student Visa</h2>
              <p className="text-body mb-6">
                Students who wish to study at an accredited academic institution in the
                United States must apply for an F-1 or M-1 student visa. Student visas are
                governed by a strict set of complex rules and deadlines. Even the smallest
                mistake can lead to denial of your student visa application or jeopardize
                your M-1 or F-1 status once you are in the United States. Representing
                foreign national students in student visa related matters is one of Pandev
                Law’s niche practice areas. Our principal attorney, Adrian, started
                working with students through his affiliation with universities and his
                work as an adjunct professor. Over the years, Pandev Law has become a
                leading law firm in all matters related to M-1 and F-1 student status,
                having represented numerous students through various student visa related
                issues, including:
              </p>
              <div className="accordion" id="internationalBusinessLaw">
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseZero"
                      aria-expanded="true"
                      aria-controls="internationalBusinessLawCollapseZero">
                      <strong>Student Visa Application</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseZero"
                    className="collapse show"
                    aria-labelledby="internationalBusinessLawHeadingZero"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body my-4">
                      Pandev Law’s student visa immigration lawyers regularly represent
                      M-1 and F-1 visa applicants at US embassies and consulates
                      worldwide. Our attorneys are known for their ability to create
                      effective strategies even in the most complex student visa cases. In
                      addition to first time student visa applicants, we regularly
                      represent students who wish to reapply for an M-1 or F-1 student
                      visa after initially receiving a denial. Relying on our deep
                      understanding of the student visa regulations, our student visa
                      immigration lawyers critically analyze past denials to craft
                      successful reapplication strategies. Our immigration lawyers also
                      counsel students who are applying for a student visa extension. We
                      have a long track record of success with student visa applications,
                      having obtained approvals for students whose M-1 or F-1 status was
                      previously terminated and who had past visa denials and immigration
                      violations.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseOne"
                      aria-expanded="false"
                      aria-controls="internationalBusinessLawCollapseOne">
                      <strong>OPT Business Formation</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseOne"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingOne"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body my-4">
                      Pandev Law is the leading law firm in
                      <a
                        href="https://www.pandevlaw.com/blog/employment-based-immigration-f1-students/"
                        target="_blank"
                        rel="noopener noreferrer">
                        OPT business formation
                      </a>
                      . We combine our experience in startup, business, and immigration
                      law to advise student entrepreneurs who
                      <a
                        href="https://www.pandevlaw.com/blog/employment-based-immigration-f1-students/"
                        target="_blank"
                        rel="noopener noreferrer">
                        wish to start a business during their OPT period
                      </a>
                      . Post-completion Optional Practical Training (OPT) provides
                      12-months of employment authorization after graduation to F-1
                      students seeking employment in their field of study. The regulations
                      specifically allow students to be self-employed during this period
                      as long as they fulfill certain requirements, such as having all
                      necessary business licenses. For entrepreneurial students, OPT
                      self-employment can be an incredible opportunity to start and scale
                      a business in the United States. Many of our clients went from
                      founding their company while on OPT to raising venture capital and
                      transitioning to other employment-based visas, such as O-1 or E-2
                      visas. Combining our extensive venture financing and immigration
                      experience, our attorneys form companies for OPT students that are
                      compliant with immigration laws and also meet the expectations of
                      angel investors and venture capital funds.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseTwo"
                      aria-expanded="false"
                      aria-controls="internationalBusinessLawCollapseTwo">
                      <strong>Change of Status Student Visa</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseTwo"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingTwo"
                    data-parent="#internationalBusinessLaw">
                    <p className="mt-4">
                      In some cases the student visa applicant is already in the United
                      States in another status, such as J-1 or B-2. In these cases, the
                      student may not wish to depart the United States and attend an F-1
                      visa interview at a foreign embassy or consulate outside of the
                      United States. Our student visa lawyers represent clients in F-1
                      change of status cases. We are familiar with the issues that
                      frequently arise when changing status to F-1. Our student visa
                      immigration lawyers develop practical solutions that minimize risks
                      and ensure that clients maintain valid immigration status as they
                      await approval of their F-1 change of status application.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingThree">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseThree"
                      aria-expanded="false"
                      aria-controls="internationalBusinessLawCollapseThree">
                      <strong>Student Visa Transfer</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseThree"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingThree"
                    data-parent="#internationalBusinessLaw">
                    <div className="mt-4">
                      Sometimes after starting their studies in the United States,
                      students wish to transfer to a different college, school, or other
                      academic institution. Our student visa immigration lawyers have
                      extensive experience helping students navigate the M-1 and F-1
                      transfer process. We are familiar with all the strict deadlines
                      governing student visa transfers. We ensure that the transfer-in
                      school is SEVP-certified and that all SEVIS records are properly
                      released by the transfer-out school. We often work with Designated
                      School Officials to facilitate a smooth transfer process for our
                      student clients. Our comprehensive student visa transfer services
                      allow students to focus on the important logistical and academic
                      aspects of changing schools, while we handle all student visa
                      related matters.
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingFour">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseFour"
                      aria-expanded="false"
                      aria-controls="internationalBusinessLawCollapseFour">
                      <strong>F-1 Reinstatement</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseFour"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingFour"
                    data-parent="#internationalBusinessLaw">
                    <div className="mt-4">
                      If a foreign national student fails to maintain status, the
                      Designated School Official may terminate the student’s F-1 status.
                      In such cases, the student must either apply for reinstatement of
                      student status or depart the United States. Our student visa
                      immigration lawyers understand the consequences of SEVIS record
                      terminations and immigration violations. We recognize that sometimes
                      students cannot depart the United States immediately upon
                      termination of their status and must apply for reinstatement. Our
                      immigration lawyers have the requisite experience and know-how to
                      guide you through your F-1 reinstatement process.
                    </div>
                  </div>
                </div>
                <div>
                  <div id="internationalBusinessLawHeadingFive">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseFive"
                      aria-expanded="false"
                      aria-controls="internationalBusinessLawCollapseFive">
                      <strong>OPT EAD Application</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseFive"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingFive"
                    data-parent="#internationalBusinessLaw">
                    <div className="mt-4">
                      A critical part of applying for F-1 post-completion Optional
                      Practical Training (OPT) is submitting a timely I-765 Application
                      for Employment Authorization. Once the application is approved, the
                      student is issued an Employment Authorization Document (EAD) that
                      serves as valid work authorization for up to 12 months after the
                      student’s graduation, or 36 months in case of STEM OPT applications.
                      Our student visa immigration lawyers regularly represent F-1
                      students in employment authorization applications. Our attorneys
                      know all relevant deadlines and understand the necessary evidence
                      that must accompany each application. We ensure that student clients
                      don’t make any irreversible errors in their application that could
                      jeopardize their ability to work in the United States.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/individual-immigration/j-1-services.png"
                alt="J-1 Services"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">J-1 Services</h2>
              <p className="text-body mb-5">
                The J-1 visa is reserved for exchange visitors coming to the United States
                to participate in a program administered by the US Department of State
                (DOS). There are several different J-1 visa program categories, including:
                teachers, au pairs, trainees, professors and research scholars, short-term
                scholars, foreign medical graduates, specialists, international and
                government visitors, camp counselors, college students, secondary school
                students, and students entering the United States for summer work travel.
                Our immigration lawyers counsel clients on all matters related to J-1
                status, including:
              </p>
              <div className="accordion" id="InvestorVisaUSA">
                <div className="mb-5">
                  <div id="InvestorVisaUSAHeadingZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#InvestorVisaUSACollapseZero"
                      aria-expanded="true"
                      aria-controls="InvestorVisaUSACollapseZero">
                      <strong>J-1 Visa Application & Change of Status</strong>
                    </h2>
                  </div>
                  <div
                    id="InvestorVisaUSACollapseZero"
                    className="collapse show"
                    aria-labelledby="InvestorVisaUSAHeadingZero"
                    data-parent="#InvestorVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      Pandev Law’s individual immigration practice counsels applicants
                      seeking to obtain a J-1 visa or to change status to J-1. Before
                      applying for a J-1 visa or change of status, applicants must obtain
                      a Form DS-2019, Certificate of Eligibility for Exchange Visitor from
                      the J-1 program sponsor. J-1 applicants must also demonstrate that
                      they have a residence in a foreign country to which they intend to
                      return after the program end date. There are various other
                      requirements specific to each J-1 program category. Our immigration
                      lawyers are intimately familiar with the J-1 visa requirements for
                      all categories and provide legal advice and representation to those
                      wishing to obtain a J-1 visa. Our law firm is known for successful
                      results in complex J-1 cases, including those of applicants with
                      past immigration violations and visa denials. We focus on developing
                      effective, practical immigration solutions.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="InvestorVisaUSAHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#InvestorVisaUSACollapseOne"
                      aria-expanded="false"
                      aria-controls="InvestorVisaUSACollapseOne">
                      <strong>J-1 Visa Waiver</strong>
                    </h2>
                  </div>
                  <div
                    id="InvestorVisaUSACollapseOne"
                    className="collapse"
                    aria-labelledby="InvestorVisaUSAHeadingOne"
                    data-parent="#InvestorVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      Section 212(e) of the Immigration and Nationality Act mandates that
                      certain J-1 visa holders and their depends in J-2 status return to
                      their home country for two years before before they can adjust
                      status to permanent resident or change status to another
                      nonimmigrant classification, such as H-1B or L-1. Alternatively to
                      returning home for two years, individuals subject to Section 212(e)
                      may apply for a waiver of the two-year home residency requirement.
                      If your J-1 waiver application is approved, you do not have to
                      return to your home country for two years and may proceed to adjust
                      or change status in the United States. Our immigration lawyers
                      represent clients with applications based on all five J-1 waiver
                      grounds:
                    </p>
                    <ul className="corporateLawOrderedList list-bullets">
                      <li className="noBold">
                        No Objection Statement from the applicant’s home country{' '}
                      </li>
                      <li className="noBold">
                        Request by an interested US government agency{' '}
                      </li>
                      <li className="noBold">
                        Persecution faced by the applicant if forced to return to their
                        home country{' '}
                      </li>
                      <li className="noBold">
                        Exceptional hardship to a US citizen or lawful permanent resident
                        or child if the applicant had to depart the United States for two
                        years{' '}
                      </li>
                      <li className="noBold">Conrad 30 waivers for foreign physicians</li>
                    </ul>
                    <p className="text-body mt-4 mb-5">
                      Pandev Law’s individual, employment-based, and family-based
                      immigration practices work together to counsel J-1 waiver
                      applicants, their employers, and US citizen and permanent resident
                      relatives on developing effective strategies for waiving the
                      two-year home residency requirement. We rely on our lawyers’
                      in-depth immigration experience to craft comprehensive solutions
                      that meet client’s professional and personal goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/individual-immigration/waivers-of-inadmissibility.jpg"
                alt="Waivers of Inadmissibility"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Waivers of Inadmissibility</h2>
              <p className="text-body mb-6">
                Some foreign nationals are barred from entering the United States because
                they are deemed inadmissible. If you are determined to be inadmissible,
                you will not be able to adjust status to become a permanent resident in
                the United States or obtain a visa to enter the United States. Past
                immigration violations, criminal convictions, fraud or wilful
                misrepresentation, or false claims to US citizenship, among others, can
                all render you inadmissible. Fortunately, some grounds of inadmissibility
                may be waived, thereby allowing your visa or adjustment of status
                application to be granted.
                <br />
                <br />
                Our immigration attorneys have extensive experience with the
                inadmissibility waiver process for both immigrant and nonimmigrant visa
                applications. We represent clients in Section 212(d)(3) waiver
                applications filed concurrently with nonimmigrant visa applications at US
                embassies and consulates worldwide. Our immigration lawyers routinely file
                I-601 applications to waive inadmissibility and I-601A unlawful presence
                waiver applications with US Citizenship and Immigration Services (USCIS).
                We also develop thorough I-601 waiver strategies for immigrant visa
                applicants and provide strategic counsel on obtaining a waiver during
                immigrant visa interviews at US embassies and consulates.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/individual-immigration/visitor-visa.jpg"
                alt="Visitor Visa"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">Visitor Visa</h2>
              <p className="text-body mb-6">
                Travelers may enter the United States on a visitor visa for short,
                temporary trips lasting up to six months. The B-1 business visitor visa
                and B-2 visitor for pleasure visa are often overlooked solutions for many
                individual immigration goals. Our immigration attorneys help clients
                obtain a B-1 visa to travel to the United States for professional
                conferences, business meetings, contract negotiations, or to install
                machinery or equipment purchased from a foreign company. We also advise on
                B-2 visa applications for individuals who wish to travel to the United
                States for pleasure, whether it’s to spend time at their winter home in
                Florida, to visit a child attending college in the United States, or to
                visit family living in the United States. Pandev Law is known for devising
                effective visitor visa strategies for applicants with past immigration or
                criminal violations or past visa refusals. Our immgration lawyers use
                their years of experience to advise and guide clients through their
                visitor visa applications. Our goal is to secure a 10-year B-1 or B-2 visa
                for clients so that they may effortlessly travel to the United States for
                a decade.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/individual-immigration/dependent-visa.jpg"
                alt="Dependent Visa"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Dependent Visa</h2>
              <p className="text-body mb-6">
                All principal nonimmigrant visa categories have an accompanying dependent
                visa category reserved for spouses and dependent children under the age of
                21 of the principal visa holder. Dependent visas allow immediate family
                members to accompany the principal visa beneficiary to the United States.
                Often employer sponsors will cover the application costs and legal fees
                associated with the principal beneficiary’s visa but will not do so for
                accompanying family members. Pandev Law is often retained to create a
                strategy and represent the principal applicant’s immediate family members
                in their visa or change/extension of status applications. Our attorneys
                work closely with both principal beneficiaries and their immediate family
                members to ensure implementation of a comprehensive solution that allows
                the entire family to reside together in the United States. Our goal is to
                take away the stress associated with immigration applications and to allow
                your family to continue you normal day-to-day routine as we take care of
                the dependent visa applications.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/individual-immigration/request-for-evidence-responses.jpg"
                alt="Request for Evidence Responses"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">
                Request for Evidence Responses
              </h2>
              <p className="text-body mb-6">
                If US Citizenship and Immigration Services (USCIS) cannot make a
                determination on a case based on the evidence initially submitted with the
                application, the agency will issue a Request for Evidence (RFE) or a
                Notice of Intent to Deny (NOID). In its RFE or NOID letter, the USCIS
                officer assigned to your case will explain how the evidence you submitted
                is insufficient and will provide you with an opportunity to submit
                additional evidence demonstrating your eligibility for the desired
                benefit. You must respond before the deadline and include all evidence
                requested in the RFE or NOID otherwise USCIS may deny your application.
                Clients often retain our immigration attorneys after they receive an RFE
                or NOID on their application. Our immigration lawyers have years of
                experience responding to RFEs and NOIDs and understand the most critical
                evidence for each application type. We review clients’ initial filings and
                supplement the record as needed through a well-crafted response. Our goal
                is to obtain a swift approval of your case after filing a response to the
                RFE or NOID.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/individual-immigration/freedom-of-information-act.jpg"
                alt="Freedom of Information Act"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Freedom of Information Act</h2>
              <p className="text-body mb-6">
                The Freedom of Information Act (FOIA) provides every foreign national the
                opportunity to obtain their immigration file from the government. This can
                be done by filing a formal request with the appropriate government agency,
                such as the US Department of State (DOS) for consular and visa files, US
                Citizenship and Immigration Services (USCIS) for records of applications
                and petitions for immigration benefits, US Customs and Border Protection
                (CBP) for entry records including secondary inspection records, US
                Immigration and Customs Enforcement (ICE) for immigration arrest records,
                and US Department of Justice, Executive Office of Immgiration Review (DOJ
                EOIR) for immigration court case files. In some cases, FOIA requests are
                filed because it is critical to review the entire record to ensure the
                most optimal immigration strategy is chosen for a case. In other
                instances, documents to establish a critical fact in a case may be in the
                government’s possession and the only way to access these is through a FOIA
                request. Regardless of the reason you may wish to obtain your immigration
                case file, our attorneys can represent and guide you in the submission of
                your request.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/individual-immigration/case-expedite-mandamus.jpg"
                alt="Case Expedite & Mandamus"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">Case Expedite & Mandamus</h2>
              <p className="text-body mb-6">
                If your case has been pending with US Citizenship and Immigration (USCIS)
                for a long time, you may request that the agency expedite the processing
                of your case. This can be achieved through submitting a USCIS case inquiry
                request online or an expedite request over the phone with a USCIS
                representative. USCIS will respond with an email requesting that you
                submit evidence demonstrating why your case merits expedited processing.
                Our immigration attorneys have experience assisting clients in expediting
                their immigration cases and can help determine the best strategy for your
                long pending immigration case. Our immigration attorneys also file
                petitions for writs of mandamus to compel USCIS to take action on a case.
                A writ of mandamus is a civil lawsuit filed in the Federal District Courts
                of the United States. If the judge agrees that your case has been pending
                for an unreasonably long time, the judge will issue an order compelling
                the government to adjudicate the case. After a judicial order is issued,
                cases are usually decided by USCIS within a matter of weeks. As a Trial
                Attorney at the US Department of Justice, our principal attorney, Adrian,
                gained uniquely valuable experience defending USCIS in such mandamus cases
                in US District Courts nationwide. Our immigration lawyers use their years
                of experience combined with Adrian’s thorough understanding of the
                strategies and arguments USCIS employs in defending mandamus cases to
                deliver results to clients.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/individual-immigration/appeals-litigation.jpg"
                alt="Appeals & Litigation"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Appeals & Litigation</h2>
              <p className="text-body mb-6">
                An adverse decision by the government on your immigration case does not
                mean that all hope is lost. If you receive a denial in your case, you may
                refile the case with the relevant government agency, file an
                administrative appeal or motion to reopen or reconsider, or file a lawsuit
                under the Administrative Procedures Act challenging the government’s
                incorrect decision based on an arbitrary and capricious interpretation of
                the laws and regulations. While serving as a Trial Attorney at the US
                Department of Justice, Office of Immigration Litigation, in Washington DC,
                our principal attorney, Adrian, defended USCIS in such District Court
                challenges. Our immigration lawyers have a track record of successfully
                appealing and reopening denials issued by USCIS. We represent clients
                before the USCIS Administrative Appeals Office (AAO), the Board of
                Immigration Appeals (BIA), US District Courts, and US Courts of Appeal to
                challenge incorrect decisions and deliver the results our clients deserve.
                <br />
                <br />
                In addition to challenging agency decisions, our immigration litigation
                services also include denaturalization defense. Denaturalization is the
                process by which the US government takes an individual’s US citizenship
                for their membership in a terrorist or subversive group, dishnorable
                discharge from the military, inadmissibility at the time of adjustment of
                status, or misrepresentation of material information in their
                naturalization or adjustment of status application, among other reasons.
                The denaturalization process starts with the US Department of Justice
                filing a lawsuit in US District Court against you, requesting the judge to
                revoke your US citizenship. As a Trial Attorney, our principal attorney,
                Adrian, litigated denaturalization cases on behalf of the US Department of
                Justice. Adrian’s experience provides our individual immigration practice
                with a unique understanding of the motivations behind the government’s
                initiation of a denaturalization case. It also makes us intimately
                familiar with proven, successful defense strategies that we use to
                preserve our clients’ US citizenship.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/individual-immigration/global-entry-appeal.jpg"
                alt="Global Entry Appeal"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">Global Entry Appeal</h2>
              <p className="text-body mb-6">
                The Global Entry program is an international travel program that allows
                frequent travelers to pass through US customs faster by using an expedited
                admission process. To be eligible for Global Entry one must submit an
                application and pass a background check, including an in-person interview
                with a Department of Homeland Security officer. The immense time savings
                provided by the Global Entry program are incredibly valuable to frequent
                international travelers. This is why a denied application or revocation of
                Global Entry can be such a burden. Fortunately, if your Global Entry
                application is denied, you may file an appeal.
                <br />
                <br />
                Our immigration lawyers know how to overcome most grounds for a denial.
                Our immigration attorneys perform a critical analysis of the decision and
                craft a strategy tailored to your case. In most cases, Global Entry
                applications are denied because the agency believes that the applicant
                poses a security risk based on criminal convictions or customs violations.
                To overcome an adverse decision, our immigration lawyers submit an appeal
                to either the Enrollment Center or the Customs and Border Protection
                Ombudsman highlighting your good moral character, your need for Global
                Entry, and the burden not having Global entry will place on you and your
                family. If you have a record of criminal convictions or customs
                violations, we demonstrate your rehabilitation and submit evidence proving
                that you are not a security risk to the United States.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/individual-immigration/cbp-currency-seizures.jpg"
                alt="CBP Currency Seizures"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">CBP Currency Seizures</h2>
              <p className="text-body mb-6">
                When entering or leaving the United States with cash or other monetary
                equivalents exceeding $10,000, you must file a report with US Customs and
                Border Protection (CBP). If you fail to report and CBP finds you carrying
                currency in excess of $10,000, the agency has the authority to seize your
                currency. There are generally three options to retrieve your seized
                currency: (1) filing an administrative petition with CBP, (2) making an
                offer in compromise, or (3) requesting a judicial action. Our attorneys
                have a strong understanding of the laws and regulations governing currency
                seizures as well as the procedures of the CBP Fines, Penalties, and
                Forfeitures department. We devise custom strategies tailored to each
                client’s needs. The amount of currency seized, the port of entry, and the
                nature of monetary equivalents will influence the best strategy in your
                case. Our attorneys know how to negotiate with CBP or to fight against the
                agency in an administrative or judicial action. Regardless of the strategy
                chosen, our focus stays the same – to help you recover as much of your
                cash assets as possible.
              </p>
            </div>
          </div>
          <Signature />
        </div>
      </div>
      {get(category, 'posts.nodes.length', 0) > 0 && (
        <>
          <hr className="my-8" />
          <CategorySlider index={2} category={category} />
        </>
      )}
      <AbTestingDeck
        title="Individual Immigration Client Reviews"
        testimonials={INDIVIDUAL_IMMIGRATION_TESTIMONIALS}
      />
      <PilarNavigation />
      <CtaDeck />
      <SuccessStoriesDeck posts={posts} />
    </Layout>
  );
}

export const query = graphql`
  query IndividualServicesPageQuery {
    category: wpCategory(slug: { eq: "individual-immigration" }) {
      ...BlogLandingHeroPost
    }
    posts: allWpPost(
      limit: 9
      sort: { date: DESC }
      filter: {
        categories: { nodes: { elemMatch: { databaseId: { eq: 4 } } } }
        slug: {
          in: [
            "naturalization-approval-203"
            "b-1-visa-approval-71"
            "e-2-approval-for-child-of-e-2-visa-holder-185"
            "b-2-visitor-visa-approval-69"
            "i-601a-waiver-approval-221"
            "h-4-approval-for-spouse-of-h-1b-status-holder-211"
            "naturalization-application-approved-62"
            "ead-approval-57"
            "travel-document-approval-51"
          ]
        }
      }
    ) {
      edges {
        node {
          id: databaseId
          title
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              altText
            }
          }
          slug
          excerpt
        }
      }
    }
  }
`;

export default IndividualImmigration;
